import React, { useState } from "react";
import './Home.css';
import Recommend from '../Recommend';
import '../ArticleSearch.css'

function HomeT({ searchContent }) {
 

  // const [keyword, setKeyword] = useState("");  
  



  return ( 
    <div className="home-container">
      
      {searchContent ? (   

      <div className="article-container" style ={{backgroundColor: '#529be4', display: "contents", justifyContent: "space-around"}} >
      <div className="article-grid" style ={{backgroundColor: '#529be4'}}>
      <h2>Relevant Results:</h2>
       
        {searchContent.map((article, index) => (
          <div key={index} className="article-card">
            <h3 className="article-title">{article.title}</h3>
         
            <a href={article.url} target="_blank" rel="noopener noreferrer" className="read-more">
              Read more
            </a>
          </div>
        ))}
      </div>
    </div>
      ) : (
        <div> 
      <Recommend />    
      </div>
     
    )}
    </div>
  );
}

export default HomeT;