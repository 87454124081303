import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './logo.png'; 
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 

import Card from 'react-bootstrap/Card'; 
import UseToggle from "./UseToggle"; 

function NavT({ onSearch }) {

  const { isToggled, toggle } = UseToggle();

  const [showModal, setShowModal] = useState(false);

  // Toggle modal visibility
  const toggleModal = () => setShowModal(!showModal);
 
  const [keyword, setKeyword] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
 
  const handleSearchChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearchSubmit = async () => { 
   

      if (!keyword.trim()) {
        setError("Please enter a keyword.");
        return;
      }
  
      setLoading(true);
      setError(null);
    
      try {
         const response = await fetch(`https://crawler-microservice-latest.onrender.com/search/${keyword}`);  // https://blog-aggregator-znlp.onrender.com
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        onSearch(data); 
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

 
  return (
    // <nav className="navbar">
    //   <div className="logo"> <Link to="/">
    //       <img src={logo} className="App-logo" alt="logo" style={{ height: '50px', cursor: 'pointer' }} />
    //     </Link></div>
    //   <div className="nav-links">
    //     <Link to="/">Home</Link>
    //     <Link to="/blog">Blog</Link>
    //     <Link to="/profile">Profile</Link>
    
      
    //   </div>
    //   <div className="nav-right">
    //   <input
    //     type="text"
    //     value={keyword}
    //     onChange={handleSearchChange} 
    //     placeholder="Enter keyword"
         
    //   />
    //     <button onClick={handleSearchSubmit}>Search</button>
    //   </div> 
    // </nav>
<div>
<Navbar expand="lg"  className="bg-blueish" style={{ backgroundColor: '#0f1629' }}> 
      <Container fluid>

        <Navbar.Brand href="/"><div className="logo"> <Link to="/">
           <img class="navbar-brand-dark" src={logo} className="App-logo" alt="logo" style={{ height: '50px', cursor: 'pointer' }} />
         </Link></div></Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px', display: 'flex', justifyContent: 'right', flex: 1 }}
            navbarScroll
          >
            <div className="nav-links"> 
             
         <Link class="btn btn-primary btn-lg active" role="button" aria-pressed="true" to="/book" style={{ backgroundColor: '#ca9ee9' }}>Books</Link>
         <Link class="btn btn-primary btn-lg active" role="button" aria-pressed="true" to="/resource" style={{ backgroundColor: '#ca9ee9' }}>Resource</Link>
         <Link class="btn btn-primary btn-lg active" role="button" aria-pressed="true" to="/techbook" style={{ backgroundColor: '#ca9ee9' }}>Tech Cookbook</Link>
        {/* <Link class="btn btn-primary btn-lg active" role="button" aria-pressed="true" to="/profile" onClick={toggleModal}>Profile</Link> */}
        
       </div>
       
            {/* <NavDropdown title="Menu" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown> */}
        
          </Nav>
         
       <div className="nav-right" style={{ maxHeight: '100px', display: 'flex', justifyContent: 'center', flex: 1 }}>
       <input
         type="text"
         value={keyword}
         onChange={handleSearchChange} 
         placeholder="Enter keyword"
         
        />
          <button class="btn btn-primary btn-lg active" role="button" aria-pressed="true" onClick={handleSearchSubmit}>Search</button>
       </div> 
         
       <button type="button" class="btn btn-primary btn-lg active" onClick={toggleModal} style={{ backgroundColor: '#ca9ee9', alignItems: 'right' }}>
        Profile
      </button>  
        </Navbar.Collapse>
      </Container>
  
    </Navbar>



      {/* Modal */}
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document" >
            <div className="modal-content" style={{ backgroundColor: '#529be4' }}>
         
              <div className="modal-body">
              <Card style={{ width: '30rem' }} >
     
     <Card.Img variant="top" src="https://fastly.picsum.photos/id/2/5000/3333.jpg?hmac=_KDkqQVttXw_nM-RyJfLImIbafFrqLsuGO5YuHqD-qQ"/> 
     <Card.Body>
       <Card.Title>Bengaluru Developer</Card.Title>
       <Card.Text>
         Software Developer
         Cloud Technology Enthusiast
       </Card.Text>
       <Button variant="primary">Explore</Button>
     </Card.Body>
   </Card>
              </div>
              <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                  Close
                </button> 
            </div>
          </div>
        </div>
      )}
</div>

  );
}

export default NavT;