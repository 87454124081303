import React from 'react';
import './Blog.css'; 
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function Res() {

    const resources = [
        {
          name: "JWT",
          description: "JSON Web Tokens are an open, industry standard RFC 7519 method for representing claims securely between two parties.JWT.IO allows you to decode, verify and generate JWT..",
          link: "https://jwt.io/",
        },
        {
          name: "{JSON} Placeholder",
          description: "Free fake and reliable API for testing and prototyping.",
          link: "https://jsonplaceholder.typicode.com/",
        },
        {
          name: "ipify API",
          description: "A Simple Public IP Address API",
          link: "https://www.ipify.org/",
        },
        {
          name: "IP / CIDR Calculator",
          description: "CIDR (Classless Inter-Domain Routing) notation is a compact method for specifying IP address ranges and network masks. It is widely used in network configuration and management.",
          link: "https://cidr.xyz/",
        },
    
        {
            name: "MarkFlow",
            description: "Inspect. Modify. Copy.",
            link: "https://markflow.app/",
          },
    
          {
            name: "Reqres",
            description: "Test your front-end against a real API ",
            link: "https://reqres.in/",
          },
          {
            name: "Freedium",
            description: "Freedium: Your paywall breakthrough for Medium!",
            link: "https://freedium.cfd/",
          },
          {
            name: "REST Assured",
            description: "Testing and validating REST services in Java is harder than in dynamic languages such as Ruby and Groovy. REST Assured brings the simplicity of using these languages into the Java domain.",
            link: "https://rest-assured.io/",
          },
          {
            name: "REST Assured",
            description: "Testing and validating REST services in Java is harder than in dynamic languages such as Ruby and Groovy. REST Assured brings the simplicity of using these languages into the Java domain.",
            link: "https://rest-assured.io/",
          },
          {
            name: "RoadMap",
            description: "roadmap.sh is a community effort to create roadmaps, guides and other educational content to help guide developers in picking up a path and guide their learnings. ",
            link: "https://roadmap.sh/",
          },
      ];
  return (
    // <div >
    //   <h2>Resources</h2>
    //   {/* <ul style={{ width: '10rem',display: "-ms-flexbox", justifyContent: "center" }}> */}
    //     {resources.map((resource, index) => (
    //       <li key={index} style={{ marginBottom: "10px" }}>
          
    //       <Card style={{ width: '10rem',display: "-ms-flexbox", justifyContent: "center" }}>
    //   <Card.Body>
    //     <Card.Title>{resource.name}</Card.Title>
    //     <Card.Text>
    //       {resource.description}
    //     </Card.Text>
    //     <a href={resource.link}>{resource.link}</a>
    //   </Card.Body>
    // </Card>
    //       </li>
    //     ))}
    //   {/* </ul> */}
      
    // </div> style ={{backgroundColor: '#529be4'}}
    <div style={{ padding: "20px", backgroundColor: '#529be4' }}>
  <h2>Resources</h2>
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      gap: "20px", // Space between cards
      justifyContent: "center", // Align cards in the center
    }}
  >
    {resources.map((resource, index) => (
      <Card
        key={index}
        style={{
          flex: "1 1 calc(33.33% - 20px)", // Responsive width (3 cards per row with gap)
          minWidth: "200px", // Minimum card width
          maxWidth: "400px", // Maximum card width
        }}
      >
        <Card.Body>
          <Card.Title>{resource.name}</Card.Title>
          <Card.Text>{resource.description}</Card.Text>
          <a href={resource.link} target="_blank" rel="noopener noreferrer">
            Visit Resource
          </a>
        </Card.Body>
      </Card>
    ))}
  </div>
</div>


  );
}

export default Res;