import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavT from './NavT';
import SideT from './SideT';
import HomeT from './HomeT';
import BookT from './BookT';
import './App.css';
import Footer from './Footer';
import Profile from '../Profile'
import Res from './Res';
import TechBook from './TechBook' 

function AppT() {

  const [searchContent, setSearchContent] = useState(''); 

  // Added state to handle search content
  const handleSearch = (content) => {
   
    setSearchContent(content);
  };
 

  return (
    <Router>
      <div className="app-container">
        <NavT onSearch={handleSearch}/>
        <SideT />
        <div className="content-container">
          <Routes>
          <Route path="/" element={<HomeT searchContent={searchContent} />} /> 
            {/* <Route path="/" element={<HomeT />} /> */}
            <Route path="/book" element={<BookT />} />
            <Route path="/resource" element={<Res />} />
            <Route path="/techbook" element={<TechBook />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/"/>
          </Routes>
        </div>
        <Footer />  
      </div>
    </Router>
  );
}

export default AppT;