import { useEffect , useState} from "react";


function IP(){

    const[ipaddr,setIP] = useState();
    const[geo,setGeo] = useState();
    const [loading, setLoading] = useState(false);
     
    const [error, setError] = useState(null);

    useEffect(() => {
      
        const fetchIP = async () => {
          try {
            setLoading(true);
            const response = await fetch('https://api.ipify.org/?format=json', {
              method: "GET", 
            }); 
    
            const data = await response.json();
            setIP(data.ip);
          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        }
          fetchIP();
      }, []);

      console.log(ipaddr);
 
        
      useEffect(() => {

        if (!ipaddr) {
            console.log("IP is not yet set, skipping fetchGeo"); // Debugging
            return;
          }
      
        const fetchGeo = async () => {
          try {
            setLoading(true);
            const response = await fetch(`https://geo.ipify.org/api/v2/country,city?apiKey=at_xi2jVo5euThRlZs2wE3llMqh7mXaq&ipAddress=${ipaddr}`, {
              method: "GET", 
            });
     
            const geo = await response.json();
            setGeo(geo);
          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
    
        fetchGeo(); // Call the async function
      }, [ipaddr]);

      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error: {error}</p>;
   if (!geo) return <p>No data available.</p>; 

      return (
        
<div style={{ fontSize: "12px", display: "flex", gap: "20px" }}>
  <div>
    <strong>IP Address:</strong> {geo.ip}
  </div>
  <div>
    <strong>City:</strong> {geo.location?.city}
  </div>
  <div>
    <strong>Country:</strong> {geo.location?.country}
  </div>
</div>
      );

};

export default IP