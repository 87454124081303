import React from 'react'; 
import './Footer.css'
import IP from './Ip';

const CURRENT_YEAR = new Date().getFullYear();

function Footer() {
  return (  
 
    <footer 
  className="footer text-center py-1 position-fixed bottom-0 w-100" 
  style={{ backgroundColor: '#0f1629', padding: '5px 0' }}  // Reduced padding to reduce height
>
  <p className="mb-0" style={{ marginBottom: '5px' }}>
    &copy; {CURRENT_YEAR} www.r-o-o-t.info. All rights reserved.
  </p>
  <p 
    style={{ 
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'flex-start', 
      marginRight: '20px', 
      marginBottom: '0' // Removed extra margin to reduce space
    }}
  >
    <IP />
  </p>
</footer>


  );
}

export default Footer;