import React, { useEffect, useState } from "react";
import './Sidebar.css'; 

function SideT() {

  const [blogs, setBlogs] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const displayBlogs = async () => {
  try {
     const response = await fetch(`https://crawler-microservice-latest.onrender.com/blogs`);  //https://crawler-microservice-latest.onrender.com
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    setBlogs(data); 
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};


useEffect(() => {displayBlogs();}, []);
  return (
    <div class="list-group">
    <aside className="sidebar"> 
    
      <ul>
      
      {blogs.map((url, index) => (
          <li key={index}> 
          
               <a href="#" class="list-group-item list-group-item-action" > </a>
              <a href={url} class="list-group-item list-group-item-action list-group-item-primary" >{url}</a>
           
          </li>
        ))}
      </ul>
    </aside>
    </div>
  );
}

export default SideT