import React from 'react';
import './Blog.css';

function BookT() {

  const books = [
    {
      title: "Spring Security in Action, Second Edition",
      author: "Laurentiu Spilca",
      image :"https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1696886424i/198014930.jpg",
      goodreadsLink: "https://www.goodreads.com/book/show/198014930-spring-security-in-action-second-edition",
      descp: "Spring Security in Action, Second Edition reveals the Spring Security techniques and tools you need to defend your apps against attack. In this book, author Lauren?iu Spilca, a skilled Java and Spring developer, will teach you how to build your own authorization server, manage system users, configure secure endpoints, and prevent cross-site scripting and request forgery attacks. This new second edition delves into the OAuth 2/OpenID Connect stack and details SecurityFilterChain for security configu"
    },
    {
      title: "Amazon Web Services in Action",
      author: "Michael Wittig",
      image:"https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1444881923i/26778699.jpg",
      goodreadsLink: "https://www.goodreads.com/book/show/26778699-amazon-web-services-in-action",
      descp: "The book will teach you about the most important services on AWS. You will also learn about best practices regarding automation, security, high availability, and scalability. Purchase of the print book includes a free eBook in PDF, Kindle, and ePub formats from Manning Publications. About the Technology Physical data centers require lots of equipment and take time and resources to manage. If you need a data center, but don't want to build your own, Amazon Web Services may be your solution. Whether you're analyzing real-time data, building software as a service, or running an e-commerce site, AWS offers you a reliable cloud-based platform with services that scale. All services are controllable via an API which allows you to automate your infrastructure. About the Book Amazon Web Services in Action introduces you to computing, storing, and networking in the AWS cloud."
    },
    {
      title: "Code: The Hidden Language of Computer Hardware and Software",
      author: "Charles Petzold",
      image:"https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1709792682i/44882.jpg",
      goodreadsLink: "https://www.goodreads.com/book/show/44882.Code",
      descp: "It’s a cleverly illustrated and eminently comprehensible story—and along the way, you’ll discover you’ve gained a real context for understanding today’s world of PCs, digital media, and the Internet. No matter what your level of technical savvy, CODE will charm you—and perhaps even awaken the technophile within."
    },
    {
      title: "Programming AWS Lambda: Build and Deploy Serverless Applications with Java",
      author: "John Chapin, Mike Roberts",
      image:"https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1585196855i/43506167.jpg",
      goodreadsLink: "https://www.goodreads.com/book/show/43506167-programming-aws-lambda",
      descp: " Get an introduction to serverless, functions-as-a-service, and AWS Lambda. Learn how to deploy working Lambda functions to the cloud.Program Lambda functions and learn how to get data in and outBuild and package Java-based Lambda code and dependencies Create serverless applications by building a serverless API and data pipeline Test your serverless applications using automated techniques Apply advanced techniques to build production-ready applications"
    },
    {
      title: "Clean Code: A Handbook of Agile Software Craftsmanship",
      author: "Robert C. Martin",
      image:"https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1436202607i/3735293.jpg",
      goodreadsLink: "https://www.goodreads.com/book/show/3735293-clean-code?ref=rae_2",
      descp: "This book is a must for any developer, software engineer, project manager, team lead, or systems analyst with an interest in producing better code."
    },
  ];

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", backgroundColor: '#529be4', justifyContent: "space-around", display: "grid" }} >
      <h2>Book List</h2>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {books.map((book, index) => (
          <li key={index} style={{ marginBottom: "10px" }}>
            <div className="article-horizontal-card" key={index}>
        <div className="article-content">
          <h2 className="article-title">{book.title}</h2>
          <div className='side'>
          <img src={book.image} width="200" height="300"/> 
          <p style={{ margin: "0 0 10px", lineHeight: "1.6", color: "#333" }}>
            {book.descp ? book.descp : "No description available."}
          </p>
          </div>
          <p className="article-author">
            {book.author ? `By ${book.author}` : "Author: Unknown"}
          </p>
        </div>
        <div className="article-action">
          <a
            href={book.goodreadsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="read-more-btn"
          >
            Check Out
          </a>
        </div>
      </div>
          </li>
        ))}
      </ul>
      
    </div>

  );
}

export default BookT;