import { useState } from "react";

function UseToggle(initialState = false) {


  const [isToggled, setIsToggled] = useState(initialState);

  const toggle = () => setIsToggled((prev) => !prev);

  return { isToggled, toggle };
  
}

export default UseToggle;
