import React, { useEffect, useState } from "react";
import "./Recommend.css";



function Recommend() { 

  useEffect(() => {
    const fetchInitialArticles = async () => {
      try {
        const response = await fetch('https://api.jsonbin.io/v3/b/677b7cd6e41b4d34e47095a2', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Access-Key": '$2a$10$C1T/WS1aOYHlB95D3L.eO.43z9FSovMRAQwuJKiY95KYUStMnEGyW',
            "SameSite":"None",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setTemp(data.record); // Set the fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialArticles(); // Call the async function
  }, []);

  
 const [temp, setTemp] = useState();  

  const [articles, setArticles] = useState(  
    
    [{ id: 1, title: "AWS Lambda turns 10: A rare look at the doc that started it", url: "https://www.allthingsdistributed.com/2024/11/aws-lambda-turns-10-a-rare-look-at-the-doc-that-started-it.html", author: "allthingsdistributed" },
    { id: 2, title: "Monoliths are not dinosaurs", url: "https://www.allthingsdistributed.com/2023/05/monoliths-are-not-dinosaurs.html", author: "Unknown" },
    { id: 3, title: "	AWS services explained in one line each", url: "https://adayinthelifeof.nl/2020/05/20/aws.html", author: "adayinthelifeof" },
    {
      id: 4,
      title: "AWS Tips I Wish I'd Known Before I Started",
      url: "https://www.hackernews.com",
      author: "Unknown",
    },
    {
      id: 5,
      title: "AWS Application Load Balancer",
      url: "https://aws.amazon.com/blogs/aws/new-aws-application-load-balancer/",
      author: "AWS",
    },
    {
      id: 6,
      title: "AWS Secrets Manager: Store, Distribute, and Rotate Credentials Securely",
      url: "https://aws.amazon.com/blogs/aws/aws-secrets-manager-store-distribute-and-rotate-credentials-securely/",
      author: "AWS",
    },
    {
      id: 7,
      title: "How AI coding companions will change the way developers work",
      url: "https://www.allthingsdistributed.com/2023/04/how-ai-coding-companions-will-change-the-way-developers-work.html",
      author: "allthingsdistributed",
    }]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

 const items=["Amazon","EC2","S3","Cloud","Lambda"] 

   const pickRandomItem = () => {
     const randomIndex = Math.floor(Math.random() * items.length);
      return items[randomIndex];
 
  }
      useEffect(() => {pickRandomItem();}, []);

  const handleSearch = async () => {
    
  const key= pickRandomItem();
    try {
       const response = await fetch(`https://crawler-microservice-latest.onrender.com/random`);  //https://crawler-microservice-latest.onrender.com
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setArticles(data); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

 useEffect(() => {handleSearch();}, []);
 
                    const placeholderArticles = [
                      { id: 1, title: "Google", url: "https://www.google.com", author: "Unknown" },
                      { id: 2, title: "Render", url: "https://www.render.com", author: "Unknown" },
                      { id: 3, title: "AWS", url: "https://www.aws.com", author: "Unknown" },
                      {
                        id: 4,
                        title: "Hacker News",
                        url: "https://www.hackernews.com",
                        author: "Unknown",
                      },
                      {
                        id: 5,
                        title: "Cloud Tech",
                        url: "https://www.cloudtech.com",
                        author: "Unknown",
                      },
                    ];
                  
                    const displayedArticles = loading || error ? placeholderArticles : articles;
return (
    <div className="articles-container" >
    <h1 className="articles-heading">Recommended Articles</h1>
    {articles.map((article, index) => (
      <div className="article-horizontal-card" key={index}>
        <div className="article-content">
          <h2 className="article-title">{article.title}</h2>
          <p className="article-author">
            {article.author ? `By ${article.author}` : "Author: Unknown"}
          </p>
        </div>
        <div className="article-action">
          <a
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
            className="read-more-btn"
          >
            Read More
          </a>
        </div>
      </div>
    ))}
  </div>
  );
};

 

export default Recommend;