import React, { useState, useEffect } from 'react';
import './TechBook.css'
import { Card } from 'react-bootstrap';

function TechBook() {

  const blogs = [
    {
      title: "Understanding React",
      description: "A beginner's guide to understanding the basics of React.",
      link: "https://yourblog.com/react-basics",
    },
    {
      title: "JavaScript Tips and Tricks",
      description: "Advanced techniques to improve your JavaScript skills.",
      link: "https://yourblog.com/js-tips",
    },
    {
      title: "CSS for Modern Web Design",
      description: "Learn the latest trends in CSS for responsive web design.",
      link: "https://yourblog.com/css-modern",
    },
  ];
  
    const [data, setData] = useState(null);
  
    useEffect(() => {
      fetch('https://cloud-wor7.onrender.com')
        .then(response => response.json())
        .then(json => setData(json))
        .catch(error => console.error(error));
    }, []);
  
    return (
      <div style={{ padding: "20px", backgroundColor: '#529be4', justifyContent: "space-around" }}>
      <h2>My Blogs</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px", // Space between cards
          justifyContent: "center", // Align cards in the center
        }}
      >
        {blogs.map((blog, index) => (
          <Card
            key={index}
            style={{
              flex: "1 1 calc(33.33% - 20px)", // Responsive width (3 cards per row with gap)
              minWidth: "250px", // Minimum card width
              maxWidth: "400px", // Maximum card width
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
              borderRadius: "10px", // Rounded corners
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {blog.title}
              </Card.Title>
              <Card.Text>{blog.description}</Card.Text>
              <a
                href={blog.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#007bff",
                  fontWeight: "bold",
                }}
              >
                Read Blog
              </a>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  
   
    );
  }
  
  export default TechBook;